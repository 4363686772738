const latestReactApps = [
  {
    slug: 'color-scheme-generator',
    title: "Color Scheme Generator",
    details: ["Generate the perfect palette by toggling and saving your favorite colors."],
  },
  {
    title: "Tints & Shades",
    slug: 'tints-and-shades',
    details: ["Monochromatic Colors Generator", "Get 20 hues and 20 shades by entering a valid CSS color name, RGB, or hex value."],
  },
  {
    title: "Download Images",
    slug: 'download-images',
    details: ["Built using the unsplash api."],
  },
]
export default latestReactApps
